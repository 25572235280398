export default {
  page: {
    title: {
      base: 'Platobná brána Autopay',
      start: 'Výber spôsobu platby',
      visadata: 'Visa Mobile',
      continue: 'Pokračovať – výber spôsobu platby',
      wait: 'Očakávanie',
      confirmation: 'Potvrdenie platby',
      thankYou: 'Ďakujem za platbu',
      error: 'Chyba stránky',
      errorNotFound: 'Stránka nenájdená',
      secureBack: 'Načítava sa stránka',
      maintenance: 'Webová stránka je v údržbe',
      regulationsApproval: 'Predpisy a zásady ochrany osobných údajov',
      toTransferData: 'podrobnosti o prevode',
      sessionTimeout: 'Stránka po skončení relácie',
      paywayError: 'Platobný kanál je nedostupný'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Zväčšiť veľkosť písma',
      decreaseFontSize: 'Zmenšiť veľkosť písma',
      changeContrast: 'Zmeniť kontrast',
      changeLanguage: 'Zmeniť jazyk stránky',
      skipToContent: 'Preskočiť na obsah',
      skipToContentLabel: 'Preskočiť na hlavný obsah',
      logoTitle: 'Logo stránky'
    },
    sessionTimer: {
      timeOut: 'Relácia vypršala',
      sessionEnd: 'Koniec relácie za:',
      label: 'Predĺžiť čas relácie',
      button: 'Predĺžiť'
    }
  },
  footer: {
    text1: 'Máte ďalšie otázky týkajúce sa online platieb alebo našich iných služieb? <a title="Otvorí sa v novom okne" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Prejdite na stránku Pomocníka</a>',
    text2: {
      part1: 'Správcom osobných údajov je spoločnosť Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. Poskytnutie osobných údajov je dobrovoľné, ale nevyhnutné na vybavenie predloženej žiadosti. Právny základ, účel, obdobie spracúvania osobných údajov a práva Používateľa, ako aj ďalšie dôležité informácie týkajúce sa zásad spracúvania osobných údajov sú podrobne uvedené v <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Prejdite na stránku s dokumentom Zásady ochrany osobných údajov (128 KB, PDF)" rel="noreferrer">Zásady ochrany osobných údajov spoločnosti Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Zobraziť ďalšie údaje',
    linkShowMoreText: 'Čítajte viac',
    linkShowLessTitle: 'Skryť ďalšie informácie',
    linkShowLessText: 'Čítajte menej'
  },
  paywall: {
    header: 'Vybrať spôsob platby',
    info: {
      paywayNotAvailable: 'Je nám to veľmi ľúto, ale zdá sa, že zvolený spôsob platby nie je v tomto čase na vašom zariadení dostupný.',
      applePayNotAvailable: 'Je nám to veľmi ľúto, ale zdá sa, že platba pomocou Apple Pay nie je v tomto čase na vašom zariadení možná.',
      googlePayNotAvailable: 'Je nám to veľmi ľúto, ale zdá sa, že platba pomocou Google Pay nie je v tomto čase na vašom zariadení možná.'
    },
    paywayGroup: {
      inactiveMessage: 'Platba momentálne nie je k dispozícii',
      notice: {
        novelty: 'NOVINKA',
        promotion: 'ŠPECIÁLNA PONUKA',
        recommended: 'ODPORÚČAME',
        mastercard: 'RÝCHLO A BEZPEČNE',
        mastercardC2P: 'RÝCHLO A BEZPEČNE',
        blik: 'BLIKOMANIA - Zaregistrujte sa a vyhrajte diamanty!',
        visaMobile: 'Plaťte hladko a bezpečne'
      },
      bank: {
        many: 'bánk',
        one: 'banka',
        two: 'banky'
      },
      wallet: {
        many: 'peňaženiek',
        one: 'peňaženka',
        two: 'peňaženky'
      },
      otp: {
        many: 'spôsoby',
        one: 'spôsobom',
        two: 'spôsoby'
      },
      blikPayLater: {
        info: 'Dozvedieť sa viac'
      }
    },
    paywayList: {
      label: 'Skupina typov platby',
      linkSelectOther: 'Vybrať iný'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Mám účet v inej banke'
    },
    paywaySwitch: {
      methodShortSingle: 'Jednorazové',
      methodLongSingle: 'Jednorazové platby',
      methodShortAuto: 'Cyklický',
      methodLongAuto: 'Opakujúce sa platby'
    },
    form: {
      payway: {
        label: 'Vybrať spôsob platby'
      },
      card: {
        label: 'Zadajte údaje o karte',
        iframe: {
          title: 'Vyplňte formulár s údajmi o kreditnej karte'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Doplniť formulár s telefónnym číslom'
        },
        status: {
          pending: {
            statusText: 'Čakanie na potvrdenie',
            header: 'Potvrďte platbu v mobilnej aplikácii',
            descriptionText1: 'Platbu potvrdíte v aplikácii Visa Mobile alebo v bankovej aplikácii s aktívnou službou Visa Mobile',
            descriptionText2: 'Po potvrdení transakcie v aplikácii počkajte na výsledok platby',
            descriptionText3: 'Trvá zrušenie transakcie',
            cancelButton: 'Zrušenie platby'
          },
          success: {
            statusText: 'Ďakujeme za Váš nákup!',
            descriptionText: 'Platba úspešne dokončená'
          },
          error: {
            statusText: 'Uistite sa, že máte dostatok finančných prostriedkov na platobnej karte použitej pri platbe Visa Mobile.',
            descriptionText: 'Platba zamietnutá'
          }
        },
        apps: 'Aplikácie podporujúce platby Visa Mobile',
        help: 'Potrebujete pomôcť?',
        info: 'Dozvedieť sa viac',
        secure: 'Platba bezpečná vďaka šifrovaniu SSL',
        modal2: {
          header: 'Pre potvrdenie platby otvorte aplikáciu Visa Mobile alebo aplikáciu banky, v ktorej máte aktívnu službu Visa Mobile',
          listText1: 'máte nainštalovanú aplikáciu Visa Mobile alebo aktívnu službu Visa Mobile v mobilnej aplikácii vašej banky',
          listText2: 'máte zapnuté upozornenia v aplikácii Visa Mobile alebo v aplikácii banky, v ktorej máte aktívnu službu Visa Mobile',
          listText3: 'číslo, ktoré ste uviedli je správne: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Uistite sa tiež, že...'
        }
      },
      click2pay: {
        loaderText: 'Hľadáme vašu e-mailovú adresu<br>v <strong>Platbe kliknutím</strong>',
        saveCard: {
          text1: 'Uložte si kartu',
          text2mc: 'Vaše údaje budú bezpečne uložené v <strong>Mastercard Click to Pay</strong>',
          text2visa: 'Vaše údaje budú bezpečne uložené v službe <strong>Visa Click to Pay</strong>',
          link: 'Viac informácií'
        },
        email: {
          labelForClick2Pay: 'E-mailová adresa pre upozornenia na platby'
        },
        cardSelect: {
          selected: 'Vybraté',
          select: 'Vybrať',
          payOther: 'Zadajte údaje o karte',
          back: 'Späť'
        },
        cardSelected: {
          header: 'Použite kartu uloženú v Click to Pay',
          date: 'platné do',
          buttonChange: 'Change'
        },
        codeForm: {
          description: 'Vašu e-mailovú adresu sme našli v službe Click to Pay.',
          codeSentEmail: 'Zadajte jednorazový kód, ktorý sme poslali na adresu {email}, aby ste sa mohli prihlásiť do svojho účtu.',
          codeSentPhone: 'Zadajte jednorazový SMS kód, ktorý sme poslali na číslo {phone}, aby ste sa mohli prihlásiť do svojho účtu.',
          codeSent: 'Zadajte jednorazový kód, ktorý sme poslali na {validationChannel}, aby ste sa mohli prihlásiť do svojho účtu.',
          codeLabel: 'Zadajte kód',
          codePlaceholder: 'zadajte kód',
          codeLabelFlow: 'Kód',
          codeSendLabel: 'Odoslať nový kód',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'alebo',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Pridajte toto zariadenie do svojho zoznamu dôveryhodných<br><span>Nabudúce toto zariadenie rozpoznáme a budete platiť bez prihlásenia</span>',
          buttonSubmit: 'Next',
          buttonChangeCard: 'Zadajte podrobnosti o karte'
        },
        info: {
          description: 'Platba kartou je šifrovaná a bezpečná. Transakcia bude autorizovaná pomocou 3DSecure na webovej stránke banky.'
        },
        loginForm: {
          emailLabel: 'Zadajte svoju e-mailovú adresu na prihlásenie do Click to Pay',
          emailPlaceholder: 'e-mailová adresa',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Prihlásiť sa',
          buttonBack: 'Späť'
        },
        newUserForm: {
          header: 'Uložiť kartu v Click to Pay',
          description: 'Na uloženie vašej karty potrebujeme niekoľko ďalších podrobností.',
          emailLabel: 'Zadajte e-mailovú adresu',
          emailPlaceholder: 'e-mailová adresa',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Krajina',
          firstnameLabel: 'Meno',
          firstnamePlaceholder: 'meno',
          firstnameLabelFlow: 'Meno',
          lastnameLabel: 'Priezvisko',
          lastnamePlaceholder: 'priezvisko',
          lastnameLabelFlow: 'Priezvisko',
          phoneLabel: 'Telefónne číslo',
          phonePlaceholder: 'telefónne číslo',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kód',
          countryCodeLabelFlow: 'Kód',
          trustedLabel: 'Pridajte toto zariadenie do svojho zoznamu dôveryhodných<br><span>Nabudúce toto zariadenie rozpoznáme a budete platiť bez prihlásenia</span>',
          termsMastercardLabel: 'Pokračovaním prijímate <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Zmluvné podmienky</a> a beriete na vedomie, že vaše údaje budú spracované v súlade s <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Zásady ochrany osobných údajov</a>Mastercard.',
          termsVisaLabel: 'Pokračovaním vyjadrujete súhlas so <a href="{c2pVisaTermsUrl}" target="_blank">zmluvnými podmienkami riešenia Visa</a> a rozumiete, že vaše údaje budú spracované v súlade s <a href=" {c2pVisaPrivacyUrl}" target="_blank">Zásady ochrany osobných údajov spoločnosti Visa</a>.',
          cookieVisaLabel: 'Pokračovaním prijímate <a href="{c2pVisaCookieUrl}" target="_blank">Pravidlá používania súborov cookie spoločnosti Visa</a>.',
          c2pInfo: 'Zaplatiť kliknutím použije tieto informácie na overenie vás a odoslanie overovacích kódov na toto číslo. Môžu sa účtovať poplatky za správy/dáta.',
          buttonSubmit: 'Uložiť a zaplatiť',
          buttonBack: 'Plaťte bez uloženia karty'
        },
        profile: {
          header: 'Prihlásiť sa a Click to Pay',
          buttonChangeEmail: 'Zmeniť e-mailovú adresu'
        },
        modal: {
          title: 'Click to Pay – informácie',
          header: 'Plaťte rýchlo a bezpečne, bez prepisovania údajov o svojej karte v novom štandarde Click to Pay',
          text1: 'Pri platbe kartou hľadajte logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> a plaťte svojou platobnou kartou rýchlo a pohodlne.',
          text2: 'Click To Pay je nový štandard platieb kartou podporovaný spoločnosťami Visa a Mastercard.',
          text3: 'Kartou na svojom Click to Pay účte môžete platiť v obchodoch v Poľsku aj v zahraničí bez kopírovania údajov o karte.',
          text4: 'Údaje sú bezpečne uložené vo Visa alebo Mastercard. Všetky informácie sú plne šifrované.',
          text5: 'Pri nasledujúcich platbách funkcia Click to Pay rozpozná vašu e-mailovú adresu, takže môžete okamžite platiť uloženými kartami.',
          text6: 'Dodatočne potvrdzujete platby vo svojej banke, čo zaručuje úplnú bezpečnosť.',
          processError: 'Pri vykonávaní platby kliknutím na tlačidlo sa vyskytla chyba. Uskutočnite štandardnú platbu kartou.'
        }
      },
      email: {
        label: 'Zadajte e-mailovú adresu',
        flowLabel: 'E-mail',
        tip: 'Potrebujeme vašu e-mailovú adresu, aby sme vám mohli posielať správy o stave vašej platby.'
      },
      terms: {
        header: 'Súhlasy a vyhlásenia',
        selectAll: 'Vybrať všetko',
        fieldNotRequired: 'Nepovinné pole',
        partnerMarketingTextLess: 'Chcete od nás dostávať e-mailové, textové alebo telefonické upozornenia o najlepších ponukách, akciách, súťažiach a ďalších zaujímavých podujatiach organizovaných nami alebo našimi partnermi?',
        partnerMarketingTextMore: 'Sľubujeme, že nebudeme zasielať spam! Ak vás naše správy nudia, môžete svoj súhlas kedykoľvek odvolať',
        tradeMarketingTextLess: 'Chcete, aby sme váš e-mail alebo telefónne číslo odovzdali našim dôveryhodným partnerom, aby vám mohli poskytovať zaujímavé informácie a ponuky?',
        tradeMarketingTextMore: '"Ubezpečujeme vás, že naši partneri sú seriózne spoločnosti, ktoré rovnako ako my nemajú radi rozosielanie spamu, a svoj súhlas môžete kedykoľvek odvolať.'
      },
      termsLabelDefault: 'Oboznámil/a som sa a prijímam <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stiahnite si súbor s obchodnými podmienkami" rel="noreferrer">Obchodné podmienky</a> poskytovania platobných služieb a <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Stiahnite si pravidlá ochrany osobných údajov Autopay S.A.">Pravidlá ochrany osobných údajov</a>. Chcem, aby bola služba realizovaná okamžite a prípade odstúpenia od zmluvy viem, že mi nebudú vrátené vzniknuté náklady za služby realizované na moju žiadosť do chvíle odstúpenia od zmluvy.',
      termsReccuring: 'Ak chcete vykonať platbu súhlaste <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stiahnite si súbor s obchodnými podmienkami" rel="noreferrer">Obchodné podmienky</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Stiahnite si pravidlá ochrany osobných údajov Autopay S.A.">Pravidlá ochrany osobných údajov</a> Autopay S.A., súhlas s okamžitou realizáciou platobnej služby a súhlas s účtovaním platieb z mojej platobnej karty.',
      termsReccuringMore1: 'Súhlasím s tým, že služba bude realizovaná okamžite a v prípade odstúpenia od zmluvy viem, že mi nebudú vrátené vzniknuté náklady za služby realizované na moju žiadosť do chvíle odstúpenia od zmluvy.',
      termsReccuringMore2: 'Súhlasím s opakovaným uhrádzaním platieb spoločnosťou Autopay S.A. z mojej platobnej karty za účelom realizácie transakcie pre {receiverName}. Zásady opakovaného peňažného prevodu sú stanovené v <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stiahnite si súbor s obchodnými podmienkami" rel="noreferrer">Obchodné podmienky</a> poskytovania platobných služieb. Vyhlasujem, že poznám termíny, frekvenciu a pravidlá určovania výšky poplatkov mojej platobnej karty ako aj spôsob odvolania tohto súhlasu, ktoré som dohodol/dohodla s {receiverName}. V prípade otázok týkajúcich sa opakovaného uhrádzania platieb z platobnej karty sa  budem kontaktovať s {receiverName}.',
      mwfClauseLess: 'Ak chcete vykonať platbu, akceptujte <a href="{regulationUrl}" class="footer-text-link" target="_blank" title="Stiahnite si súbor so Zmluvnými podmienkami" rel="noreferrer">Zmluvné podmienky</a> i <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Stiahnite si súbor so Zásadami ochrany osobných údajov spoločnosti Autopay S.A.">Zásady ochrany osobných údajov spoločnosti Autopay S.A.</a> a súhlas s okamžitým vykonaním platobnej služby.',
      mwfClauseMore: 'Súhlasím s okamžitým poskytnutím služby a viem, že v prípade odstúpenia od zmluvy mi nebudú vrátené náklady.',
      mwfClauseARIA: 'Ak chcete vykonať platbu, akceptujte Zmluvné podmienky i Zásady ochrany osobných údajov spoločnosti Autopay S.A. a súhlas s okamžitým vykonaním platobnej služby. Súhlasím s okamžitým poskytnutím služby a viem, že v prípade odstúpenia od zmluvy mi nebudú vrátené náklady. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Stiahnite si súbor so Zmluvnými podmienkami</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Stiahnite si súbor so Zásadami ochrany osobných údajov spoločnosti Autopay S.A.</a>',
      paymentInfo: 'Platobný príkaz sa zadáva prostredníctvom spoločnosti Autopay S.A. so sídlom v Sopote a vykoná sa v súlade s podmienkami stanovenými vašou bankou. Po vybere banky platbu autorizujete.',
      changePaywayLink1: 'Stále môžete',
      changePaywayLink2: 'zmeniť formu platby',
      linkMore: 'VIAC',
      linkShowMoreTitle: 'Zobrazenie dodatočných informácií',
      linkLess: 'MENEJ',
      linkShowLessTitle: 'Skryť dodatočné informácie',
      submitButton: {
        visaMobile: 'Zaplatiť podľa',
        fastTransfer: 'Generovať údaje o prevode',
        mwf: 'Súhlasím a platím',
        pis: 'Súhlasím a chcem zaplatiť',
        auto: 'Naročite ponavljajoče se plačilo',
        autoPayment: 'Platba objednávky',
        other: 'Zaplatiť',
        orange: 'Potvrďte',
        c2pNext: 'Ďalej',
        c2pAccept: 'Súhlasím',
        c2pSaveAndPay: 'Uložiť a zaplatiť'
      }
    },
    frData: {
      pageTitle: 'Podrobné údaje pre prevod',
      header: 'objednajte si prevod pomocou uvedených  údajov',
      nrbLabel: 'Účet príjemcu',
      nameLabel: 'Názov príjemcu',
      addressLabel: 'Adresa príjemcu',
      titleLabel: 'Za čo',
      amountLabel: 'Suma',
      timeLabel: 'Platbu uskutočniť do ',
      linkCopy: 'Kopírovať',
      linkGetPdf: 'Stiahnuť PDF súbor s údajmi',
      linkGetPdfTitle: 'Otvorí sa v novom okne',
      linkGoToBank: 'Prihlásiť sa do banky',
      info: 'Prihláste sa do svojho internetbankingu. Skopírujte údaje do bankového prevodu. Dôležité je, aby údaje boli rovnaké, nemeňte sumu, menu, titul, atď.'
    },
    processing: {
      pageTitle: 'Stránka čakania na spracovanie transakcie',
      loaderAnimation: 'Počas čakania sa zobrazuje animácia',
      header: 'Pripravujeme vašu platobnú stránku.',
      description: 'Počkajte ešte chvíľu...',
      info: {
        header: 'Pamätajte!',
        description: 'Po dokončení transakcie nezatvárajte stránku - ak tak urobíte, vaše peniaze nebudú odoslané príjemcovi.'
      },
      loaderText: 'Prebieha prenos údajov.<br>Bude trvať maximálne 30 sekúnd.'
    }
  },
  thankYou: {
    pageTitle: 'Informácie o výsledku transakcie',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Návrat na webovú stránku partnera'
    },
    negative: {
      header: 'Platba nebola spracovaná, pretože nebola autorizovaná.',
      description: 'Ak chcete využiť ponuku konkrétneho predajcu alebo poskytovateľa služieb, vráťte sa na jeho webovú stránku, vyberte si produkt alebo službu a zaplaťte zvoleným spôsobom.'
    },
    pending: {
      header: 'Ďakujeme vám za objednanie platby prostredníctvom platobnej brány.',
      description: 'Prebieha overovanie stavu transakcie. Po dokončení procesu dostanete do svojej e-mailovej schránky informácie o stave platby'
    },
    positive: {
      header: 'Ďakujeme, vaša platba bola spracovaná ',
      description: 'Na zadanú e-mailovú adresu vám zašleme potvrdenie.'
    }
  },
  regulationsApproval: {
    header: 'Prijmite transakciu'
  },
  wait: {
    pageTitle: 'Čakacia stránka'
  },
  sessionTimeout: {
    pageTitle: 'Stránka po skončení relácie',
    header: 'Vypršal čas na dokončenie platby',
    description: 'Kedykoľvek sa môžete vrátiť na partnerskú stránku, znovu si objednať alebo využiť službu a zaplatiť zvoleným spôsobom'
  },
  paywayError: {
    pageTitle: 'Vybratý platobný kanál je nedostupný',
    header: 'Žiaľ, vybraný spôsob platby je momentálne nedostupný.',
    description: 'Vráťte sa na stránku partnera a skúste to znova neskôr.'
  },
  loader: {
    defaultAnimation: 'Animácia zobrazená počas načítavania stránky',
    slider: 'Posuvník s meniacimi sa textami',
    text1: ' Naším cieľom je dosiahnuť uhlíkovú neutralitu. Využívaním našich služieb  to to robíte aj vy.',
    text2: 'Do práce jazdíme na bicykli, čím znižujeme emisie skleníkových plynov do ovzdušia. Využívaním našich služieb to robíte aj vy.',
    text3: 'Sme poháňaní zelenou energiou. Aj vy, keď využijete naše služby.',
    text4: 'Znižujeme spotrebu papiera. Využívaním našich služieb to robíte aj vy.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Detaily',
    headerAriaLabel: 'Detaily transakcie',
    amountLabel: 'Súma k úhrade',
    receiverLabel: 'Príjemca',
    orderLabel: 'Číslo objednávky',
    amountOryginalCurrencyLabel: 'Suma v {currency}',
    currencyRateLabel: 'Výmenný kurz',
    feeLabel: 'Poplatok za spracovanie transakcie',
    customerNumberLabel: 'Číslo zákazníka',
    invoiceNumberLabel: 'Číslo faktúry',
    accountNumberLabel: 'Číslo účtu',
    paywayLabel: 'Spôsob platby',
    paywayName: 'Online bankovým prevodom',
    linkBack: 'Späť do obchodu',
    linkBackTitle: 'Nechajte transakciu a vráťte sa do obchodu',
    linkMore: 'viacej',
    linkLess: 'menej',
    toggleBtnUnfoldAriaLabel: 'Zobraziť detailné údaje o objednávke',
    toggleBtnFoldAriaLabel: 'Zbaliť údaje o objednávke'
  },
  error: {
    pageTitle: 'Chybová stránka',
    linkBackUrlSet: 'Návrat na webovú stránku partnera',
    linkBackUrlNotSet: 'Prejsť na stránku pomocníka',
    sessionTimeout: {
      title: 'Vypršal čas na dokončenie platby',
      message: 'Kedykoľvek sa môžete vrátiť na partnerskú stránku, znovu si objednať alebo využiť službu a zaplatiť zvoleným spôsobom'
    },
    notFoundError: {
      pageTitle: 'Chybová stránka 404 - stránka sa nenašla',
      title: 'Zdá sa, že ste zablúdili príliš ďaleko a nenašli ste miesto, ktoré ste chceli navštíviť.',
      message: 'Možné príčiny: stránka neexistuje alebo bola odstránená, prebieha údržba, adresa stránky nie je správna, došlo k technickej chybe.'
    },
    generalError: {
      title: 'Ľutujeme',
      message: 'Momentálne nemôžeme spracovať váš prevod,'
    },
    errFieldNotFound: {
      title: 'Ľutujeme',
      message: ''
    },
    errBadClientSource: {
      title: 'Ľutujeme',
      message: ''
    },
    nrParametersError: {
      title: 'Ľutujeme',
      message: 'Momentálne nemôžeme spracovať váš prevod,'
    },
    transactionOutdated: {
      title: 'Vypršal čas na dokončenie platby.',
      message: 'Obnoviť platbu.'
    },
    linkValidityTimeOutdated: {
      title: 'Vypršal čas na dokončenie platby.',
      message: 'Obnoviť platbu.'
    },
    transactionValidityTimeOutdated: {
      title: 'Vypršal čas na dokončenie platby.',
      message: 'Obnoviť platbu.'
    },
    multiplyTransaction: {
      title: 'Ľutujeme',
      message: 'Transakcia už existuje a čaká na platbu'
    },
    transactionCanceled: {
      title: 'Ľutujeme',
      message1: 'Transakcia bola zrušená.',
      message2: 'Obnoviť platbu.'
    },
    multiplyPaidTransaction: {
      title: 'Ľutujeme',
      message: 'Transakcia je už zaplatená.'
    },
    transactionReject: {
      title: 'Ľutujeme',
      message1: 'Ups, niečo sa pokazilo',
      message2: 'Prebieha oprava brány...'
    },
    bankDisabled: {
      title: 'Ľutujeme',
      message: 'Zvolená banka je momentálne nedostupná.'
    },
    bankTemporaryMaintenance: {
      title: 'Ľutujeme',
      message: 'Momentálne nemôžeme spracovať váš prevod,'
    },
    insufficientStartAmount: {
      title: 'Ľutujeme',
      message: 'Limit sumy prevodu bol prekročený.'
    },
    startAmountOutOfRange: {
      title: 'Ľutujeme',
      message: 'Limit sumy prevodu bol prekročený.'
    },
    nonAccountedLimitExceeded: {
      title: 'Ľutujeme',
      message: 'Platbu nie je možné spracovať, pretože predajca dosiahol mesačný limit predaja.'
    }
  },
  confirmation: {
    pageTitle: 'Informácie o priebehu platby',
    linkBack: 'Idem späť na stránku',
    linkBackTimeout: 'Návrat na webovú stránku partnera o ',
    incorrectAmount: {
      header: 'Chybná suma',
      description: 'Vašu platbu sme príjemcovi nepreviedli, pretože suma bola nesprávna.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to. Nezabudnite nemeniť žiadne údaje.'
    },
    multiplePaid: {
      header: 'Dvojitý vklad',
      description: 'Príjemca dostal vašu platbu dvakrát. Nebojte sa – peniaze vám budú vrátené.',
      description2: 'Kontaktujte príjemcu a poskytnite mu potvrdenie o platbe.'
    },
    negative: {
      header: 'Žiadna platba',
      description: 'Pri spracovaní vašej platby sa vyskytla chyba. Ale nič nie je stratené.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to.'
    },
    notFound: {
      header: 'Žiadna platba',
      description: 'Pri spracovaní vašej platby sa vyskytla chyba. Ale nič nie je stratené.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to.'
    },
    outdated: {
      header: 'Koniec času',
      description: 'Vašu platbu sme nepreviedli príjemcovi.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to.'
    },
    outdatedPaidNotRefunded: {
      header: 'Koniec času',
      description: 'Vašu platbu sme nepreviedli príjemcovi. Ak boli peniaze stiahnuté z vášho účtu, nebojte sa, peniaze vám budú vrátené do niekoľkých dní.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to.'
    },
    outdatedPaidRefunded: {
      header: 'Koniec času',
      description: 'Vašu platbu sme nepreviedli príjemcovi. Ak boli peniaze stiahnuté z vášho účtu, nebojte sa, už sme vám ich vrátili.',
      description2: 'Kliknutím na tlačidlo sa vrátite na stránku a zopakujte to.'
    },
    pending: {
      header: 'Prebieha',
      description: 'Spracovávame vašu platbu. Keď sa dostane k príjemcovi, upozorníme vás.'
    },
    positive: {
      header: 'Platené',
      visaVideo: 'Reklamné video prehraté po úspešnej transakcii',
      description: 'Previedli sme vaše prostriedky príjemcovi.'
    }
  },
  messages: {
    accessForbidden: 'Žiadny prístup',
    internalServerError: 'Vyskytla sa nečakaná chyba. Skúste to neskôr.',
    validators: {
      required: 'Povinné pole',
      pattern: 'Zadajte platnú hodnotu',
      emailRequired: 'Prosím zadajte platnú emailovú adresu',
      emailNotValid: 'Prosím zadajte platnú emailovú adresu',
      emailNotRecognized: 'Uvedená e-mailová adresa nie je registrovaná',
      emailIdentityLookup: 'Nepodarilo sa overiť e-mailovú adresu',
      emailLocked: 'Uvedená e-mailová adresa je dočasne zablokovaná v systéme Mastercard',
      paywayRequired: 'Ak chcete zaplatiť, vyberte banku',
      creditCardRequired: 'Vyplňte všetky polia',
      phoneRequired: 'Prosím zadajte platné telefónne číslo',
      regulationRequired: 'Prijať podmienky',
      requiredConsent: 'Potrebný súhlas',
      codeNotValid: 'Zadaný kód nie je platný',
      codeAccountLocked: 'Účet bol dočasne zablokovaný v systéme Mastercard'
    },
    validatorsAria: {
      required: 'Upozornenie. Pole formulára nebolo vyplnené. Požadované pole',
      pattern: 'Pozornosť. Pole obsahuje neplatnú hodnotu.',
      emailRequired: 'Upozornenie. E-mailové pole nebolo vyplnené. Zadajte platnú e-mailovú adresu',
      emailNotValid: 'Upozornenie. Vaša e-mailová adresa obsahuje chybu. Zadajte platnú e-mailovú adresu',
      emailNotRecognized: 'Pozor. Uvedená e-mailová adresa nie je zaregistrovaná.',
      emailIdentityLookup: 'Pozor. Poskytnutú e-mailovú adresu nebolo možné overiť',
      emailLocked: 'Pozor. Poskytnutá e-mailová adresa je dočasne zablokovaná v systéme Mastercard.',
      paywayRequired: 'Upozornenie. Platba nebola vybratá. Vyberte si metódu platby',
      creditCardRequired: 'Upozornenie. Vyplňte všetky polia údajmi o kreditnej karte',
      phoneRequired: 'Pozor. V políčku telefónne číslo je chyba. Zadajte platné telefónne číslo.',
      codeNotValid: 'Pozor. Poskytnutý kód nie je platný.',
      codeAccountLocked: 'Pozor. Účet bol dočasne zablokovaný v systéme Mastercard.'
    },
    success: {
      copy: 'Skopírované!',
      codeSent: 'Kód bol odoslaný!'
    },
    errors: {
      getPdf: 'Nepodarilo sa stiahnuť PDF!',
      copy: 'Nepodarilo sa skopírovať!',
      thankYouUpdate: 'Nepodarilo sa uložiť e-mailovú adresu!',
      groupInactive: 'V súčasnosti nie je možné uskutočniť prevod pomocou skupiny {groupName}. Vyberte iný spôsob platby.',
      paywayInactive: 'Tento spôsob platby je momentálne nedostupný. Vyberte iný',
      googlePayInactive: 'Platba cez Google Pay nie je vo vašom zariadení bohužiaľ dostupná.',
      applePayInactive: 'Platba cez ApplePay vyžaduje prehliadač Safari a zariadenie Apple kompatibilné s touto platobnou metódou.',
      codeNotSent: 'Opätovné odoslanie kódu zlyhalo!',
      codeCounterExceeded: 'Bol prekročený počet povolených pokusov. Zadajte podrobnosti o svojej karte ručne alebo použite iný spôsob platby.'
    }
  },
  modal: {
    closeButton: 'Zavrieť',
    confirmButton: 'Vidím'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Opakujúca sa platba',
        description: 'Zadajte údaje o karte, ktorú použijete na platbu. Automatické platby budú z vašej karty stiahnuté neskôr.',
        labelInitWithRefund: 'Overovacia platba',
        labelInitWithRefundOrange: 'Pridajte platobnú kartu',
        labelInitWithPayment: 'Platba kartou',
        descriptionInitWithRefund: 'Zadajte údaje karty, ktorú chcete, pre budúce platby, overiť. Ďalej si na  overenie správnosti údajov budeme z tejto karty účtovať 1 zlotý. Po overení Vám sumu vrátime.',
        descriptionInitWithPayment: 'Zadajte údaje karty, ktorou vykonáte platbu, a ktorú zároveň overíte pre budúce platby.'
      },
      blik: {
        label: 'BLIK',
        description: 'Plaťte okamžite pomocou služby BLIK bez prihlásenia alebo registrácie'
      },
      card: {
        label: 'Platba kartou',
        description: 'Zaplaťte svojou platobnou kartou.'
      },
      pbl: {
        label: 'Online peňažný prevod',
        description: 'Vyberte banku, z ktorej chcete uskutočniť platbu',
        descriptionVerify: 'Overenie totožnosti online',
        descriptionServiceVerify: 'Vyberte banku, ktorá vám umožní overiť službu online'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Platba mobilom Visa'
      },
      wallet: {
        label: 'Google&nbsp;Pay | Apple&nbsp;Pay',
        description: 'Zaplatiť bez prihlásenia sa do internetového bankovníctva'
      },
      installments: {
        label: 'Rozložiť na splátky',
        description: 'Plaťte za svoje nákupy výhodnými splátkami'
      },
      alior: {
        descriptionInfo1: 'Plaťte za svoje nákupy výhodnými splátkami',
        descriptionInfo2: '(skontrolujte podrobnosti)',
        aliorModal: {
          text1: 'V závislosti od nastavení webovej stránky, na ktorej nakupujete, môžete nakupovať produkty na splátky v nasledujúcich variantoch:',
          text2: '<ul><li>10 splátok 0%</li><li>20 splátok 0%</li><li>od 3 do 48 splátok s mesačnými nákladmi 1% (podľa uvedeného reprezentatívneho príkladu pre danú transakciu)</li></ul>'
        }
      },
      otp: {
        label: 'Zaplatiť neskôr',
        description: 'Kúpte teraz, zaplaťte neskôr',
        descriptionInfo1Mwf: 'Účet zaplaťte neskôr – do 45 dní naraz alebo v niekoľkých rovnakých splátkach',
        descriptionInfo1Ecommerce: 'Plaťte neskôr – do 45 dní naraz alebo v niekoľkých rovnakých splátkach',
        descriptionInfo2Mwf: 'Informácie o sprostredkovateľovi úveru a nákladoch',
        descriptionInfo2Ecommerce: 'Informácie o nákladoch',
        labelPayka: 'Payka',
        descriptionPayka: 'Účet zaplaťte neskôr – jednorazovo do 30 dní alebo v 4 rovnakých splátkach <a href="" id="paykaShowInModal">Informácie o sprostredkovateľovi úveru a nákladoch</a>',
        paykaModalMwf: {
          header1: 'Informácia o sprostredkovateľovi úverov',
          text1: 'Spoločnosť Autopay S.A. so sídlom v Sopote pôsobí ako sprostredkovateľ úverov Paytree S.A. so sídlom v Gdansku (Veriteľ), je oprávnená zákazníkom predkladať úverovú ponuku a presmerovať ich na webovú stránku Veriteľa, v tom do žiadosti o úver. Podrobné informácie o ponuke na: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Údaje o finančnom vyrovnaní',
          text2: 'Plaťte neskôr – do 45 dní naraz alebo v niekoľkých rovnakých splátkach. Údaje o nákladoch pre reprezentatívny príklad: Splatenie do 45 dní APR 0 %; Splátka APR 86,83 %. Podrobnosti o ponuke na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Údaje o finančnom vyrovnaní',
          text2: 'Plaťte neskôr – do 45 dní naraz alebo v niekoľkých rovnakých splátkach. Údaje o nákladoch pre reprezentatívny príklad: Splatenie do 45 dní APR 0 %; Splátka APR 86,83 %. Podrobnosti o ponuke na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        }
      },
      blikPayLater: {
        label: 'BLIK Zaplatiť neskôr',
        description: 'Služba je dostupná v Millenium Bank a VeloBank',
        modal: {
          header1: 'Kúpte teraz, zaplaťte do 30 dní',
          header2: 'BLIK kód',
          text2: 'Zadáte BLIK kód a my vám ponúkneme nákupný limit až do 4 000 PLN',
          header3: 'Kúpite si produkty a zaplatíte za ne neskôr',
          text3: 'Máte čas skontrolovať, či vám všetko vyhovuje',
          header4: 'Zaplatíte do 30 dní zadarmo',
          text4: 'Svoje nákupy môžete vrátiť aj v lehote určenej obchodom'
        }
      },
      companyPayments: {
        label: 'Podnikové nákupy',
        description: 'Odložte platbu za podnikové nákupy o 30 dní'
      },
      transfer: {
        label: 'Detaily prevodu',
        description: 'Objednajte si peňažný prevod s využitím uvedených údajov'
      },
      other: {
        label: 'Iné',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Ľutujeme. Momentálne nemôžeme spracovať váš prevod. Zvolená banka je momentálne nedostupná.',
        blockMultipleTransactions: 'Ľutujeme. Transakcia už existuje a čaká na platbu',
        blockPaidTransactions: 'Ľutujeme. Transakcia je už zaplatená. Platbu nemôžete opakovať',
        canceledTransactions: 'Transakcia, ktorú sa snažíte zaplatiť, bola zrušená a nie je k dispozícii. Začnite novú transakciu.',
        declined: 'Autorizácia zamietnutá.',
        generalError: 'Vyskytol sa dočasný problém s pripojením. Momentálne nemôžeme spracovať váš prevod. Vráťte sa neskôr.',
        insufficientStartAmount: 'Ľutujeme. Limit sumy prevodu bol prekročený.',
        internalServerError: 'Ups.. toto sa nepodarilo :(. Pracujeme na riešení tohto problému. Skúste znovu neskôr.',
        nonAccountedLimitExceeded: 'Platbu nie je možné spracovať, pretože predajca dosiahol mesačný limit predaja.',
        outdatedError: 'Vypršal čas na dokončenie platby..',
        paid: 'Transakcia bola úspešne dokončená,',
        paywayLoaded: 'Načítala sa stránka rýchleho prevodu',
        paywaylistLoaded: 'Bola načítaná stránka s výberom spôsobu platby'
      }
    }
  },
  maintenance: {
    text1: 'Prebieha novoročné upratovanie v rámci platobnej brány Autopay, a preto v súčasnosti nie je možné zadať platbu. Ospravedlňujeme sa vám za vzniknuté nepríjemnosti',
    text2: 'Brána bude opäť v prevádzke už 16  januára o 10:00 hod',
    text3: 'Ďakujeme za pochopenie.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Povedzte mi svoje meno a priezvisko',
      flowLabel: 'Meno a priezvisko',
      placeholder: 'Povedzte mi svoje meno a priezvisko'
    },
    nip: {
      label: 'Zadajte svoje daňové identifikačné číslo',
      flowLabel: 'NIP',
      placeholder: 'Zadajte svoje daňové identifikačné číslo'
    },
    customerEmail: {
      label: 'Zadajte svoju e-mailovú adresu',
      flowLabel: 'E-mail',
      placeholder: 'Zadajte svoju e-mailovú adresu'
    }
  }
}
